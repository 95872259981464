
import { Options, Vue } from "vue-class-component";
import { SearchedQuestionType } from "../types/search";
import { searchHandler } from "../handlers/question/question-handlers";
import SearchedQuestionItem from "../components/SearchedQuestionItem.vue";

@Options({
  components: {
    SearchedQuestionItem,
  },
})
export default class Search extends Vue {
  questionList: Array<SearchedQuestionType> = [];

  mounted(): void {
    searchHandler(this.$route.params.expression.toString())
      .then((questions: Array<SearchedQuestionType> | void) => {
        this.questionList = questions || [];
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
