
import { SearchedQuestionType } from "@/types/search";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Category from "./Category.vue";

@Options({
  components: { Category },
})
export default class SearchedQuestionItem extends Vue {
  @Prop() readonly question!: SearchedQuestionType;
}
